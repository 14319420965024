// 开票页面
// sle
<template>
  <div>
    <a-modal
      :confirmLoading="spinning"
      v-model:visible="visible"
      :maskClosable="false"
      ok-text="提交"
      cancel-text="取消"
      :closable="false"
      width="1000px"
      @ok="submitApplyInvoice"
      @cancel="closeView"
    >
      <template v-slot:title>
        <div class="footer flex-row justify-between align-center">
          <div class="flex-row">
            <span class="title">开票信息</span>
            <span class="money">当前开票金额：￥{{ invoice.money }}</span>
          </div>
          <span class="right">
            <a-button @click="addInvoice" type="primary" :loading="spinning">添加</a-button>
          </span>
        </div>
      </template>
      <div class="flex-column">
        <a-spin :spinning="spinning">
          <a-table
            :pagination="false"
            :columns="columns"
            :data-source="list"
            :rowKey="(record) => record.id"
            bordered
            :row-selection="{
              selectedRowKeys: selectedRows,
              onChange: onSelectChange,
              type: 'radio',
            }"
            :scroll="{ x: 800 }"
          >
            <template #operation="{ record }">
              <div class="editable-row-operations">
                <span class="flex-row justify-around">
                  <a @click="editInvoice(record.id)">编辑</a>
                  <a-popconfirm
                    cancelText="取消"
                    okText="确认"
                    title="是否确认删除"
                    @confirm="deleteInvoice(record)"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </div>
            </template>
          </a-table>
        </a-spin>
      </div>
    </a-modal>
  </div>
  <invoice-edit-view ref="invoiceView" @editInvoiceBefore="editInvoiceBefore" />
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import InvoiceEditView from './InvoiceEditView'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'

export default defineComponent({
  components: {
    'invoice-edit-view': InvoiceEditView,
  },
  data () {
    return {
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      selectedRows: [], // 当前选中项
      list: [],
      columns: [],
      invoice: {},
    }
  },
  emits: ['makeInvoiceBefore'],
  methods: {
    // 打开弹窗
    async showView (e) {
      this.invoice = e
      this.visible = true
      await this.getTableColumnData()
      this.getInvoiceTableData()
    },
    // 获取当前table的列信息
    async getTableColumnData () {
      const data = await getTableColumnData('SysEntInvoices', 'SysEntOrderInfo', true)
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取列结构失败')
          console.log(err)
        })
      this.columns = data
    },
    // 获取table数据
    getInvoiceTableData () {
      this.spinning = true
      api
        .get('/api/app/sys-ent-invoice/sys-ent-invoice-list')
        .then(({ data }) => {
          this.spinning = false
          this.list = data
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取数据失败')
          console.log(err)
        })
    },
    // 提交开票申请
    submitApplyInvoice () {
      if (this.selectedRows.length === 0) {
        this.$message.error('请选择开票信息！')
        return
      }
      const id = this.selectedRows[0]
      const invoice = this.list.find(function (item) {
        return item.id === id
      })
      this.spinning = true
      api
        .post('/api/app/sys-apply-invoice/sys-apply-invoice', {
          invoiceName: invoice.invoiceName,
          invoiceTaxNum: invoice.invoiceTaxNum,
          entAddress: invoice.entAddress,
          phoneNum: invoice.phoneNum,
          bankName: invoice.bankName,
          bankNumber: invoice.bankNumber,
          invoiceState: 0,
          invoiceMoney: this.invoice.money,
          enterpriseId: this.invoice.enterpriseId,
          orderInfoIdList: this.invoice.list,
          creUser: '',
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.$emit('makeInvoiceBefore')
          this.selectedRows = []
          this.spinning = false
          this.visible = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 关闭弹窗
    closeView () {
      this.visible = false
      this.spinning = false
      this.columns = []
      this.list = []
    },
    // table选择事件
    onSelectChange (selectedRows) {
      this.selectedRows = selectedRows
    },
    // 添加开票信息
    addInvoice () {
      this.$refs.invoiceView.addInvoice()
    },
    // 编辑开票信息
    editInvoice (id) {
      this.$refs.invoiceView.editInvoice(id)
    },
    // 删除开票信息
    deleteInvoice (e) {
      this.spinning = true
      api
        .delete('/api/app/sys-ent-invoice/sys-ent-invoice', {
          params: {
            Id: e.id,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.getInvoiceTableData()
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 新增，编辑后处理事件
    editInvoiceBefore () {
      this.getInvoiceTableData()
    },
  },
})
</script>
<style  lang="scss" scoped>
@import "@/assets/common.scss";
.title {
  font-size: 20px;
}
.money {
  margin-left: 20px;
  font-size: 12px;
  color: #ff274e;
}
.head {
  height: 30px;
  font-size: 15px;
  margin-bottom: 10px;
}
</style>
