// 开票信息新增，编辑弹窗
// sle
<template>
  <div>
    <a-modal
      :confirmLoading="spinning"
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      ok-text="保存"
      cancel-text="取消"
      @ok="invoiceViewSubit"
      @cancel="closeInvoiceView"
    >
      <a-spin :spinning="spinning">
        <a-form
          :model="obj"
          ref="formRef"
          :rules="rules"
          autocomplete="off"
          :labelCol="{ span: 5 }"
          :wrapperCol="{ span: 16 }"
          labelAlign="left"
        >
          <a-form-item label="发票抬头" required name="invoiceName">
            <a-input v-model:value="obj.invoiceName" :maxlength="30" />
          </a-form-item>
          <a-form-item label="税号" required name="invoiceTaxNum">
            <a-input v-model:value="obj.invoiceTaxNum" :maxlength="20" />
          </a-form-item>
          <a-form-item label="单位地址" required name="entAddress">
            <a-input v-model:value="obj.entAddress" :maxlength="100" />
          </a-form-item>
          <a-form-item label="电话" required name="phoneNum">
            <a-input v-model:value="obj.phoneNum" :maxlength="11" />
          </a-form-item>
          <a-form-item label="开户银行" name="bankName">
            <a-input v-model:value="obj.bankName" :maxlength="30" />
          </a-form-item>
          <a-form-item label="银行账户" name="bankNumber">
            <a-input v-model:value="obj.bankNumber" :maxlength="30" />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'

export default defineComponent({
  emits: ['editInvoiceBefore'],
  data () {
    return {
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      add: false, // 是否新增操作
      title: '', // 弹窗标题
      obj: {}, //
      rules: {
        invoiceName: [
          {
            required: true,
            message: '请输入发票抬头',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '长度请控制在30以内',
            trigger: 'blur',
          },
        ],
        invoiceTaxNum: [
          {
            required: true,
            message: '请输入税号',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: '长度请控制在20以内',
            trigger: 'blur',
          },
        ],
        entAddress: [
          {
            required: true,
            message: '请输入单位地址',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 100,
            message: '长度请控制在100以内',
            trigger: 'blur',
          },
        ],
        phoneNum: [
          {
            required: true,
            message: '请输入电话',
            trigger: 'blur',
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确的11位手机号',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
    }
  },
  methods: {
    // 新增开票信息
    addInvoice () {
      this.visible = true
      this.title = '添加开票信息'
      this.add = true
    },
    // 编辑开票信息
    editInvoice (id) {
      this.visible = true
      this.title = '编辑开票信息'
      this.add = false
      this.getInvoiceData(id)
    },
    // 获取选中开票信息的详情数据
    getInvoiceData (id) {
      this.spinning = true
      api
        .get('/api/app/sys-ent-invoice/sys-ent-invoice', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取开票信息信息失败')
          console.log(err)
        })
    },
    // 取消，关闭开票信息编辑窗口
    closeInvoiceView () {
      this.visible = false
      this.title = ''
      this.add = false
      this.$refs.formRef.resetFields()
      this.obj = {}
    },
    // 确认添加开票信息操作
    invoiceViewSubit () {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.spinning = true
          const obj = this.obj
          api
            .post('/api/app/sys-ent-invoice/or-update-sys-ent-invoice', obj)
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.closeInvoiceView()
              this.$emit('editInvoiceBefore')
              this.spinning = false
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
})
</script>
