// 订单信息
// sle
<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <page-head title="订单信息" />
    <div class="middle flex-row align-center justify-between">
      <div class="left flex-row align-center">
        <div class="selectRow">
          订单状态：
          <a-select
            v-model:value="pagination.orderState"
            style="width: 200px"
            @change="selectChange()"
          >
            <a-select-option
              v-for="r in orderStateDrop"
              :key="r.linkKey"
              :value="r.linkKey"
              >{{ r.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="selectRow" style="margin-left: 20px">
          发票状态：
          <a-select
            v-model:value="pagination.invoiceState"
            style="width: 200px"
            @change="selectChange()"
          >
            <a-select-option
              v-for="r in invoiceStateDrop"
              :key="r.linkKey"
              :value="r.linkKey"
              >{{ r.name }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div class="right flex-rowReverse align-center">
        <a-button class="button" @click="makeInvoiceMore()">
          合并开票
        </a-button>
        <a-button
          disabled
          title="敬请期待"
          class="button"
          @click="conversion()"
        >
          兑换
        </a-button>
      </div>
    </div>
    <div class="table">
      <a-spin :spinning="spinning">
        <a-table
          :columns="columns"
          :data-source="list"
          :rowKey="(record) => record.id"
          :pagination="pagination"
          :row-selection="{
            selectedRowKeys: selectedRows,
            onChange: onSelectChange,
            fixed: true,
          }"
          @change="paginationChange"
          :scroll="{ x: 1300 }"
        >
          <template #orderDetails="{ record }">
            <a-tooltip>
              <template #title>{{ record.orderDetails }}</template>
              <span class="ecllipsis" style="width: 100%">
                {{ record.orderDetails }}
              </span>
            </a-tooltip>
          </template>
          <template #operation="{ record }">
            <div class="editable-row-operations">
              <span v-if="record.orderType != 2">
                <a
                  @click="makeInvoiceClick(record)"
                  v-if="record.orderState == 1 && record.invoiceState == 0"
                >
                  开票
                </a>
                <a
                  :class="disabledBtn === true ? 'is_free' : ''"
                  @click="disabledBtn === true ? '' : payOrderInfo(record)"
                  v-if="record.orderState == 0 && record.invoiceState == 0"
                >
                  支付
                </a>
                <a-popconfirm
                  cancelText="取消"
                  okText="确认"
                  title="是否取消订单？"
                  @confirm="cancelPayment(record)"
                >
                  <a style="padding-left: 30px" v-if="record.orderState == 0">
                    取消</a
                  >
                </a-popconfirm>
              </span>
            </div>
          </template>
        </a-table>
      </a-spin>
    </div>
  </div>
  <make-invoice ref="makeInvoiceView" @makeInvoiceBefore="makeInvoiceBefore" />
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import MakeInvoice from './MakeInvoice'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'

export default defineComponent({
  components: {
    'page-head': PageHead,
    'make-invoice': MakeInvoice,
  },
  data () {
    return {
      disabledBtn: false,
      closeApplicationView: false,
      loading: false, // 是否正在开启/关闭应用
      spinning: false, // 是否加载
      list: [], // table数据
      columns: [], // table列
      orderStateDrop: [], // 订单状态下拉
      invoiceStateDrop: [], // 开票状态下拉
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        invoiceState: '',
        orderState: '',
      }, // 分页部分
      selectedRows: [], // 当前选中部分
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getTableColumnData()
    this.getTableData()
    this.getOrderStateSelectList()
    this.getInvoiceStateSelectList()
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    // if (tenantList.isFree === true) {
    //   this.disabledBtn = true
    // }
  },
  // 方法
  methods: {
    // 获取当前table的列信息
    async getTableColumnData () {
      const data = await getTableColumnData('SysEntOrderInfo', 'enterprise', true)
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.error('获取列结构失败' + err)
        })
      this.columns = data
    },
    // 获取订单状态列表
    getOrderStateSelectList () {
      api
        .get('/api/app/sys-ent-order-info/order-state-select-list')
        .then(({ data }) => {
          const obj = { linkKey: '', code: 'all', name: '全部' }
          data.splice(0, 0, obj)
          this.orderStateDrop = data
        })
        .catch(err => {
          console.log(err)
          // this.$message.error('获取订单状态失败' + err)
        })
    },
    // 获取开票状态列表
    getInvoiceStateSelectList () {
      api
        .get('/api/app/sys-ent-order-info/invoice-state-select-list')
        .then(({ data }) => {
          const obj = { linkKey: '', code: 'all', name: '全部' }
          data.splice(0, 0, obj)
          this.invoiceStateDrop = data
        })
        .catch(err => {
          // this.$message.error('获取开票状态失败')
          console.log(err)
        })
    },
    // 选项变更事件
    selectChange () {
      this.pagination.current = 1
      this.getTableData()
    },
    // 获取table数据
    getTableData () {
      this.spinning = true
      api
        .get('/api/app/sys-ent-order-info/paging-sys-ent-order-info-list', {
          params: {
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
            OrderState: this.pagination.orderState,
            InvoiceState: this.pagination.invoiceState,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = data.list
          this.pagination.total = data.pageCount
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取数据失败')
          console.log(err)
        })
    },
    // 页码变更事件
    paginationChange (e) {
      this.pagination.current = e.current
      this.getTableData()
    },
    // 开票
    makeInvoiceClick (e) {
      const list = []
      list.push(e.id)
      const obj = {
        list: list,
        money: e.orderMoney,
        enterpriseId: e.enterpriseId,
      }
      this.$refs.makeInvoiceView.showView(obj)
    },
    // 支付
    payOrderInfo (e) {
      this.spinning = true
      // api
      //   .get('/api/app/sys-ent-order-info/can-be-pay-state', {
      //     params: {
      //       Id: e.id,
      //     },
      //   })
      //   .then(() => {
      //     this.spinning = false
      this.$router.push({
        path: '/EnterpriseManagement/Payment',
        query: {
          orderCode: e.orderCode,
        },
      })

      // EnterpriseManagement/Payment
      // const obj = {
      //   orderCode: e.orderCode,
      // }
      // api
      //   .post('/api/app/sys-ent-order-info/pay-for-order', obj)
      //   .then(({ data }) => {
      //     this.$message.success('操作成功')
      //     this.getTableData()
      //     this.spinning = false
      //   })
      //   .catch(err => {
      //     this.spinning = false
      //     // this.$message.error('操作失败')
      //     console.log(err)
      //   })
    },
    // 取消支付
    cancelPayment (e) {
      this.spinning = true
      const obj = {
        id: e.id,
      }
      api
        .post('/api/app/sys-ent-order-info/cancellation-order', obj)
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.getTableData()
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 兑换
    conversion () { },
    // 合并开票
    makeInvoiceMore () {
      if (this.selectedRows.length === 0) {
        this.$message.error('请选择需要合并开票的数据！')
        return
      }
      const list = []
      let money = 0
      let enterpriseId = ''
      for (let i = 0; i < this.selectedRows.length; i++) {
        const id = this.selectedRows[i]
        const element = this.list.find(function (item) {
          return item.id === id
        })
        if (element.orderState !== 1) {
          this.$message.error('只能选择订单状态为已支付的订单数据')
          return
        }
        if (element.invoiceState !== 0) {
          this.$message.error('只能选择开票状态为未开票的订单数据')
          return
        }
        list.push(id)
        money += element.orderMoney
        enterpriseId = element.enterpriseId
      }
      const obj = {
        list: list,
        money: money,
        enterpriseId: enterpriseId,
      }
      this.$refs.makeInvoiceView.showView(obj)
    },
    // 开票后处理事件
    makeInvoiceBefore () {
      this.selectedRows = []
      this.getTableData()
    },
    // table选择事件
    onSelectChange (selectedRows) {
      this.selectedRows = selectedRows
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.main {
  background: #fff;
}

.middle {
  width: 100%;
  padding: 0px 20px;
  height: 80px;
  .left {
    .selectRow {
      font-size: 13px;
    }
  }
  .right {
    .button {
      margin-left: 20px;
      width: 90px;
    }
  }
}
.table {
  padding: 0px 20px;
}
::v-deep(.ant-table-thead) > tr > th {
  text-align: center;
  border-right: none;
  padding: 12px 10px;
}
::v-deep(.ant-table-tbody) > tr > td {
  text-align: center;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: none;
}
::v-deep(.ant-table-fixed-right) table {
  border-right: 1px solid #f0f0f0;
}
.is_free {
  color: rgb(97, 97, 97);
  opacity: 0.7;
}
</style>
